import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private errorSubject: Subject<Error>;
    constructor() {
        this.errorSubject = new Subject();
    }

    public get errors(): Observable<Error> {
        return this.errorSubject.asObservable();
    }

    public publish(error: Error) {
        this.errorSubject.next(error);
    }
}
